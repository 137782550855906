<template>
  <div class="container">
    <main>
      <div class="py-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          src="../../assets/img-minhastar/logoe.png"
          alt="Logo minha star"
          height="70"
        />
        <h3>Manual Telefonia</h3>
        <router-link class="btn-back" to="/">
          <div class="d-flex justify-content-center align-items-center">
            <i class="fas fa-arrow-left"></i>
            <p style="margin-bottom: 0; margin-left: 6px; font-weight: 600">
              Voltar
            </p>
          </div>
        </router-link>
      </div>

      <div class="row g-5 justify-content-center">
        <div class="card-manual">
          <img
            class="d-block mx-auto mb-4"
            src="../../assets/img-minhastar/siga-me.png"
            alt="SigaMe"
            height="140"
          />

          <p class="titulo">Siga-Me</p>

          <p class="text-center">
            O Siga-me é um serviço que possibilita a transferência de chamadas
            recebidas em seu número para outro telefone celular ou fixo. Veja
            abaixo como utilizar:
          </p>

          <p class="text-center">
            <b>Ativar</b>
            <br />
            Digite *22* + o número do telefone que receberá a chamada + #
          </p>

          <p class="text-center">
            <b>Desativar</b>
            <br />
            Digite *22*
          </p>
        </div>
      </div>
    </main>

    <div class="my-4 mb-lg-0">
      <div class="text-sm text-center copyright text-muted">
        ©
        {{ new Date().getFullYear() }} | Star Internet e Telefonia
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManualTelefonia",
};
</script>

<style scoped>
.card-manual {
  max-width: 420px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 10px;
  border-radius: 14px;
}

.titulo {
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
}

.btn-back:hover {
  cursor: pointer;
  color: #6c6c6c;
}

@media screen and (max-width: 1023px) {
  .card-manual {
    max-width: 90%;
    padding: 20px 20px;
  }
}
</style>
